import React, { Component } from 'react';
import StoryList from './StoryList';

export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: true
        };
    }

    static defaultProps = {
        searchMap: {},
        ecosystems: [],
        handleSearch: () => { }
    }

    handleTray = () => {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
    }

    render() {
        return (
            <div className={this.state.isToggleOn ? 'sidebarContainer onCanvas' : 'sidebarContainer offCanvas'}>
                <div className="tab" onClick={this.handleTray}>
                    <div className="arrow" />
                </div>
                <div className="closeMe" onClick={this.handleTray} />
                <div className="sidebar">
                    <div className="bar">
                        <div className="sidebar--logo">
                            <h2>Mapping Ecosystem Rights</h2>
                        </div>
                        <StoryList
                            activeStory={this.props.activeStory}
                            activeStoryClicked={this.props.activeStoryClicked}
                            allStories={this.props.allStories}
                            stories={this.props.stories}
                            onStoryClick={this.props.onStoryClick}
                            handleSearch={this.props.handleSearch}
                            ecosystems={this.props.ecosystems}
                            searchMap={this.props.searchMap}
                            searchEcosystem={this.props.searchEcosystem}
                            searchSubEcosystem={this.props.searchSubEcosystem}
                            searchMunicipality={this.props.searchMunicipality}
                            handleEcosystemChange={this.props.handleEcosystemChange}
                            handleSubecosystemChange={this.props.handleSubecosystemChange}
                            handleMunicipalityChange={this.props.handleMunicipalityChange}
                            logoPath={this.props.logoPath}
                            logoPath2={this.props.logoPath2}
                            subEcosystemOptions={this.props.subEcosystemOptions}
                            showStories={this.props.showStories}
                            itemsDisabled={this.props.itemsDisabled}
                            tributaryKeys={this.props.tributaryKeys}
                            waterSourceKeys={this.props.waterSourceKeys}
                        />
                    </div>
                </div>
            </div>
        );
    }
}