import React, { useState } from "react";

const PasswordPopup = ({ onPasswordCorrect, logoPath, logoPath2 }) => {
  const [password, setPassword] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIncorrectPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_PASSWORD) {
      onPasswordCorrect();
    } else {
      setIncorrectPassword(true);
    }
  };

  return (
    <div className="password-popup-container">
      <div className="password-popup-modal">
        <h2>Mapping Ecosystem Rights</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Password:
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </label>
          <button type="submit">Submit</button>
        </form>
        {incorrectPassword && (
          <p className="incorrect-password-message">Incorrect password.</p>
        )}
      </div>
      <div className="logo-container">
        <a href="https://bioneers.org/" target="_blank" rel="noreferrer">
          <img src={logoPath} alt="Bioneers" />
        </a>
        <a
          href="https://www.centerforenvironmentalrights.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoPath2} alt="CDER" />
        </a>
      </div>
    </div>
  );
};

export default PasswordPopup;
