import React, { Component } from 'react';
import Story from "./Story";
import Filter from "./Filter";
import IntroductoryPanel from "./IntroductoryPanel";
import ReactList from 'react-list';

export default class StoryList extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.activeStoryClicked && this.props.activeStoryClicked !== prevProps.activeStoryClicked) {
            if (this._list) {
                this._list.scrollTo(0);
            }
        }
    }

    handleEcosystemChange = (ecosystem) => {
        this.props.handleEcosystemChange(ecosystem);
    }

    handleSubecosystemChange = (subecosystem) => {
        this.props.handleSubecosystemChange(subecosystem);
        if (this._list) {
            this._list.scrollTo(0);
        }
    }

    handleMunicipalityChange = (municipality) => {
        this.props.handleMunicipalityChange(municipality);
        if (this._list) {
            this._list.scrollTo(0);
        }
    }

    renderStory = (index, key) => {
        const story = this.props.stories[index];
        let storyClass = '';
        if (this.props.activeStory && this.props.activeStory === story) {
            storyClass = `story${index}`
        } else {
            storyClass = `story${index}`;
        }
        return (
            <Story
                story={story}
                onStoryClick={this.props.onStoryClick}
                storyClass={storyClass}
                key={key}
                tributaryKeys={this.props.tributaryKeys}
                waterSourceKeys={this.props.waterSourceKeys}
            />
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className="sidebar--nav">
                    <Filter
                        allStories={this.props.allStories}
                        ecosystems={this.props.ecosystems}
                        searchMap={this.props.searchMap}
                        searchEcosystem={this.props.searchEcosystem}
                        searchSubEcosystem={this.props.searchSubEcosystem}
                        searchMunicipality={this.props.searchMunicipality}
                        handleEcosystemChange={this.handleEcosystemChange}
                        handleSubecosystemChange={this.handleSubecosystemChange}
                        handleMunicipalityChange={this.handleMunicipalityChange}
                        subEcosystemOptions={this.props.subEcosystemOptions}
                        itemsDisabled={this.props.itemsDisabled}
                        stories={this.props.stories}
                    />
                </div>
                {!this.props.showStories
                    ?
                    <div className="sidebar--nav intro-panel">
                        <IntroductoryPanel
                            logoPath={this.props.logoPath}
                            logoPath2={this.props.logoPath2}
                        />
                    </div>
                    :
                    <React.Fragment>
                        <div className="stories">
                            {(this.props.stories.length > 0) && <ReactList
                                ref={list => this._list = list}
                                itemRenderer={this.renderStory}
                                length={this.props.stories ? this.props.stories.length : 0}
                                pageSize={this.props.stories ? this.props.stories.length : 0}
                                type='simple'
                            />}
                            {this.props.stories.length === 0 && <div id="noStories"><p>No municipalities found that match these filter criteria.</p></div>}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}