import React from "react";

const IntroductoryPanel = props => {

  const { logoPath, logoPath2 } = props;

  return (
    <React.Fragment>
      <h3>Introduction</h3>
      <p>
        This project was created to identify communities which are the best situated to adopt
        local laws which recognize the legally enforceable rights of particular ecosystems.
      </p>
      <p>
        “Rights of nature” laws seek to recognize human civil-rights type protections for
        ecosystems, while providing for enforcement of those protections through the
        recognition of legal standing for residents of the communities where those laws have
        been passed.
      </p>
      <p>
        In the U.S., over three dozen local governments have adopted these “rights of nature”
        laws, and several legal enforcement actions have been brought to enforce and defend
        those rights. Several countries have adopted these provisions into their national laws
        and constitutions, and localities in many other countries have adopted local and tribal
        laws recognizing the rights of ecosystems.
      </p>
      <h3>Instructions</h3>
      <p>
        <img src="/icons/dropdown.png" alt="dropdown" className="icon"></img>
        To begin using the <span className="emphasis">Mapping Ecosystem Rights</span> application, select an ecosystem using the first dropdown above, or click on one of the ecosystem geographies on the map.
      </p>
      <p>
        <img src="/icons/list.png" alt="list" className="icon"></img>
        Once you have selected an ecosystem, you will see a list of municipalities within that ecosystem in the sidebar.
      </p>
      <p>
        <img src="/icons/pin.png" alt="pin" className="icon"></img>
        For each municipality, you can click on the pin marker to zoom in on its location on the map.
      </p>      
      <p>
        <img src="/icons/csv.png" alt="csv" className="icon"></img>
        You can browse data relevant to rights of nature legislation for each municipality, and download the data as a CSV.
      </p>

      <p>
        <img src="/icons/filter.png" alt="filter" className="icon"></img>
        You can also search for municipalities within contributing ecosystem using the second dropdown, or directly by municipality name using the third dropdown.
      </p>
      <p>
        <img src="/icons/checkbox.png" alt="checkbox" className="icon"></img>
        There are also additional checkbox filters in the <strong>Filter municipalities</strong> panel (not shown for mobile devices).
      </p>
      <p>
        <img src="/icons/map.png" alt="map" className="icon"></img>
        Lastly, you can also explore municipalities within an ecosystem directly using the map, by clicking on the municipality.
      </p>
      <h3>Credits</h3>
      <p>
        This mapping project, the result of a partnership between Bioneers and the Center for
        Democratic and Environmental Rights (CDER) provides a tool to “rights of nature”
        activists to determine the best places to adopt laws which protect particular ecosystems.
        It does so by allowing users to filter localities based on proximity to the ecosystem,
        political composition of those localities, whether residents have initiative power to place
        measures directly onto the ballot, and population.
      </p>
      <div className="logos">
        <a href="https://bioneers.org/" target="_blank" rel="noreferrer"><img src={logoPath} alt="Bioneers" /></a>
        <a href="https://www.centerforenvironmentalrights.org/" target="_blank" rel="noreferrer"><img src={logoPath2} alt="CDER" /></a>
      </div>
      <h3>Data sources</h3>
          <p><strong>Ecosystem boundaries</strong> derived from USGS <a href="https://www.usgs.gov/national-hydrography/watershed-boundary-dataset" target="_blank" rel="noreferrer">Watershed Boundary Dataset</a>, HU-10 Subbasin level.</p>

          <p><strong>Counties, Towns and Municipalities</strong> accessed from United States Census Bureau <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/carto-boundary-file.html" target="_blank" rel="noreferrer">Cartographic Boundary Files</a> US 500k dataset.</p>

          <p><strong>Rivers</strong> accessed from NOAA National Weather Service <a href="https://www.weather.gov/gis/Rivers" target="_blank" rel="noreferrer">Rivers of the U.S.</a> dataset.</p>
    </React.Fragment>
  )
}

export default IntroductoryPanel;
